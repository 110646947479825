// Generated by Framer (f415c5a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MUZ0Thqa6"];

const variantClassNames = {MUZ0Thqa6: "framer-v-1n4lh75"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, WkCicIdTT: image ?? props.WkCicIdTT ?? {src: new URL("assets/1024/Skmy85WyrT6QwKJ5YF2MbDP2cY.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/Skmy85WyrT6QwKJ5YF2MbDP2cY.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/Skmy85WyrT6QwKJ5YF2MbDP2cY.jpg", import.meta.url).href} 1024w, ${new URL("assets/Skmy85WyrT6QwKJ5YF2MbDP2cY.jpg", import.meta.url).href} 1400w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WkCicIdTT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "MUZ0Thqa6", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-tVdp7", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 350, intrinsicWidth: 700, pixelHeight: 700, pixelWidth: 1400, sizes: "min(700px, 100vw)", ...toResponsiveImage(WkCicIdTT)}} className={cx("framer-1n4lh75", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"MUZ0Thqa6"} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-tVdp7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tVdp7 .framer-htlrr1 { display: block; }", ".framer-tVdp7 .framer-1n4lh75 { height: 350px; overflow: visible; position: relative; width: 700px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 350
 * @framerIntrinsicWidth 700
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WkCicIdTT":"image"}
 */
const FramerjyWtccfwL: React.ComponentType<Props> = withCSS(Component, css, "framer-tVdp7") as typeof Component;
export default FramerjyWtccfwL;

FramerjyWtccfwL.displayName = "carousel-th";

FramerjyWtccfwL.defaultProps = {height: 350, width: 700};

addPropertyControls(FramerjyWtccfwL, {WkCicIdTT: {__defaultAssetReference: "data:framer/asset-reference,Skmy85WyrT6QwKJ5YF2MbDP2cY.jpg?originalFilename=2SlideiPhone15Pro_commande.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerjyWtccfwL, [])